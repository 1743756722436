import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import SasTimestampToHumanReadableView from "../components/SasTimestampToHumanReadableView"
import SasHumanReadableToTimestampView from "../components/SasHumanReadableToTimestampView"
import SideBarView from "../components/SideBarView"
import { Link as GatsbyLink } from "gatsby"
import { styled } from '@mui/system';
import { Grid } from "@mui/material"

const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})

const SasTimestampConverter = () => {

  return (
    <Container>
      <CustomHeader title={`SAS Timestamp Conversions`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <p><Link target={`blank`} to={`https://en.wikipedia.org/wiki/SAS_language`}>SAS</Link> is a programming language
            used for statistical analysis. SAS timestamp is seconds or days since 1960.</p>
          <br/>
          <p style={{ fontWeight: "500" }}>Date</p>
          <p>The representation of SAS DateTime will be the number of days since January 1, 1960.</p>
          <br/>
          <p style={{ fontWeight: "500" }}>SAS Timestamp</p>
          <p>SAS date timestamp is nothing but second or days difference between the current timestamp and January 1,
            1960 at midnight GMT</p>
          <SasTimestampToHumanReadableView/>
          <SasHumanReadableToTimestampView/>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>

    </Container>
  )
}
export default SasTimestampConverter

export const Head = () => {
  const siteMetadata = {
    description: "Convert SAS timestamp to unix timestamp, human readable format and human readable date time to the SAS timestamp",
    description2: "",
    keywordsContent: "SAS time, timestamp converter, SAS timestamp, milliseconds, date"
  }
  return (
    <Seo
      title="SAS Timestamp Converter"
      canonicalUrl={"https://www.epochconverter.io/sas-timestamp-converter/"}
      siteMetadata={siteMetadata}
    />
  )
}
