/* eslint-disable react/no-unused-state */
import React, { Component } from "react"
import { Input, Col, Row } from "antd"
import * as styles from "./PagesLayout.module.less"
import dayjs from "dayjs"
import { Button } from "@mui/material";

import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

class SasTimestampToHumanReadableView extends Component {
  componentDidMount() {
    this.setState({ timestampValue: dayjs().valueOf() + 315619200000 })
    this.handleTimestampToDateConversion()
  }

  getValidDiffTimeStr({ diffTime, type, past }) {
    const tempDiffTime = Math.abs(diffTime)
    let extraStr = ""
    if (Number(tempDiffTime) === 0) {
      return false
    } else if (Number(tempDiffTime) > 1) {
      extraStr = "s"
    }
    const preFix = past ? "" : "In "
    const postFix = past ? " Ago" : ""
    return `${preFix}${tempDiffTime} ${type}${extraStr}${postFix}`
  }

  getRelativeTimeStr(timestampValue) {
    const tempTimestampValue = Math.round(timestampValue)
    const timeDiff = dayjs().valueOf() - tempTimestampValue
    const past = timeDiff >= 0
    const years = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "years"),
      type: "year",
      past,
    })
    const months = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "months"),
      type: "month",
      past,
    })
    const days = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "days"),
      type: "day",
      past,
    })
    const hours = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "hours"),
      type: "hour",
      past,
    })
    const minutes = this.getValidDiffTimeStr({
      diffTime: dayjs().diff(dayjs(tempTimestampValue), "minutes"),
      type: "minute",
      past,
    })
    const diffTimeStr =
      years || months || days || hours || minutes || "A few seconds ago"
    return diffTimeStr
  }

  handleTimestampToDateConversion = () => {
    let { timestampValue } = this.state || {}
    timestampValue -= 315619200000
    let units = "milliseconds"
    if (timestampValue && String(timestampValue).length > 14) {
      timestampValue /= 1000
      units = "microseconds (1/1,000,000 second)"
    } else if (timestampValue && String(timestampValue).length <= 11) {
      timestampValue *= 1000
      units = "seconds"
    }
    timestampValue*=1
    const { timeZone } = new Intl.DateTimeFormat().resolvedOptions()
    const GMTTimeStr = dayjs(timestampValue)
      .tz("GMT")
      .format("dddd, MMM DD, YYYY hh:mm:ss.SSS A")
    const currentTimeStr = dayjs(timestampValue)
      .tz(timeZone)
      .format("dddd, MMM DD, YYYY hh:mm:ss.SSS A")
    const year = dayjs(timestampValue).utc().tz(timeZone).format("YYYY")
    const month = dayjs(timestampValue).utc().tz(timeZone).format("MM")
    const day = dayjs(timestampValue).utc().tz(timeZone).format("DD")
    const hour = dayjs(timestampValue).utc().tz(timeZone).format("hh")
    const minutes = dayjs(timestampValue).utc().tz(timeZone).format("mm")
    const seconds = dayjs(timestampValue).utc().tz(timeZone).format("ss")
    const timeType = dayjs(timestampValue).utc().tz(timeZone).format("A")
    const finalTimeZone = "LTZ"
    const diffTimeStr = this.getRelativeTimeStr(timestampValue)
    this.setState({
      GMTTimeStr,
      currentTimeStr,
      units,
      displayTtoD: true,
      year,
      month,
      day,
      hour,
      minutes,
      seconds,
      timeType,
      timeZone: finalTimeZone,
      diffTimeStr,
    })
  }

  onTimestampChange = e => {
    let timestamp = e.target.value
    timestamp = timestamp
      .split("")
      .filter(n => !Number.isNaN(Number(n)))
      .join("")
    this.setState({ timestampValue: timestamp })
  }

  render() {
    const {
      timestampValue = "",
      GMTTimeStr = "",
      currentTimeStr = "",
      displayTtoD = false,
      units,
      diffTimeStr,
    } = this.state || {}
    return (
      <div id={"timestampToHumanReadableConverter"} className={styles.timestampToHumanReadableConverterContainer}>
        <Row style={{ marginTop: "50px" }}>
          <Col>
            <h2
              style={{
                color: "#4d3267",
                fontSize: "24px",
                fontWeight: "bold",
                fontFamily: "Karla, sans-serif",
              }}
            >
              Convert SAS timestamp to human-readable date and
              vice versa
            </h2>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={8} md={8} xl={8}>
            <Input
              className={styles.inputLayouts}
              label="timestamp"
              aria-label={"timestamp"}
              style={{
                // marginLeft: 0,
                borderRadius: "3px",
                outline: 0,
                boxShadow: "none",
                border: "1px solid #c7417b",
                paddingBottom: "1px",
                paddingTop: "1px",
              }}
              value={timestampValue}
              onChange={this.onTimestampChange}
            />
          </Col>
          <Col xs={24} sm={16} md={16} xl={16}>
            <Button
              className={styles.btnLayouts}
              type="primary"
              onClick={this.handleTimestampToDateConversion}
            >
              SAS Timestamp To Human-Readable
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ color: "#4d3267", textAlign: "justify", fontSize: "15px" }}
          >
            Supports Epoch timestamps in seconds, milliseconds, microseconds and
            nanoseconds.
          </Col>
        </Row>
        <div
          style={{
            display:
              displayTtoD && GMTTimeStr !== "Invalid date" ? "block" : "none",
          }}
        >
          <Row style={{ marginTop: "25px" }}>
            <Col>
              <p>
                Assuming that this timestamp is in <b>{units}</b>:
              </p>
            </Col>
          </Row>
          <Row style={{ fontSize: "16px" }}>
            <Col md={3}>
              <p>
                <b>GMT </b>
              </p>
            </Col>
            <Col>
              <p>:</p>
            </Col>
            <Col>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{GMTTimeStr}
              </p>
            </Col>
          </Row>
          <Row style={{ fontSize: "16px" }}>
            <Col md={3}>
              <p>
                <b>Current Time Zone </b>
              </p>
            </Col>
            <Col>
              <p>:</p>
            </Col>
            <Col>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{currentTimeStr}
              </p>
            </Col>
          </Row>
          <Row style={{ fontSize: "16px" }}>
            <Col md={3}>
              <p>
                <b>Relative </b>
              </p>
            </Col>
            <Col>
              <p>:</p>
            </Col>
            <Col>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{diffTimeStr}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default SasTimestampToHumanReadableView
