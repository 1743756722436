/* eslint-disable react/no-unused-state */
import React, { Component } from "react"
import { Input, Select, Col, Row } from "antd"
import * as styles from "./PagesLayout.module.less"
import dayjs from "dayjs"
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Button } from "@mui/material";

dayjs.extend(localizedFormat)

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

const TIMEZONES = [
  { key: "GMT", value: "GMT", label: "GMT" },
  { key: "LTZ", value: "LTZ", label: "Local Time" },
]

const TIMEZONE_KEY_PAIRS = {
  GMT: "GMT",
  LTZ: "Local Time",
}

class SasHumanReadableToTimestampView extends Component {
  componentDidMount() {
    this.setState({ timestampValue: dayjs().valueOf() })
    this.handleDateToTimestampConversion()
  }

  handleDateToTimestampConversionSubmit = () => {
    const { year, month, day, minutes, seconds, timeType } = this.state
    let { hour, timeZone } = this.state
    if (timeType === "PM") {
      hour = Number(hour) + 12
    }
    if (hour >= 24) {
      hour = "00"
    }

    if (timeZone === "LTZ") {
      ({ timeZone } = new Intl.DateTimeFormat().resolvedOptions())
    }

    const timeStr = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
    const unixTimestamp = dayjs.tz(timeStr, timeZone).valueOf()
    this.setState({ unixTimestamp })
  }

  handleDateToTimestampConversion = () => {
    let { timestampValue = dayjs().valueOf() } = this.state || {}
    let units = "milliseconds"
    if (timestampValue && String(timestampValue).length > 14) {
      timestampValue /= 1000
      units = "microseconds (1/1,000,000 second)"
    } else if (timestampValue && String(timestampValue).length <= 11) {
      timestampValue *= 1000
      units = "seconds"
    }
    timestampValue*=1
    const { timeZone } = new Intl.DateTimeFormat().resolvedOptions()
    const GMTTimeStr = dayjs(timestampValue)
      .tz("GMT")
      .format("dddd, MMM DD, YYYY hh:mm:ss.SSS A")
    const currentTimeStr = dayjs(timestampValue)
      .tz(timeZone)
      .format("dddd, MMM DD, YYYY hh:mm:ss.SSS A")
    const year = dayjs(timestampValue).utc().tz(timeZone).format("YYYY")
    const month = dayjs(timestampValue).utc().tz(timeZone).format("MM")
    const day = dayjs(timestampValue).utc().tz(timeZone).format("DD")
    const hour = dayjs(timestampValue).utc().tz(timeZone).format("hh")
    const minutes = dayjs(timestampValue).utc().tz(timeZone).format("mm")
    const seconds = dayjs(timestampValue).utc().tz(timeZone).format("ss")
    const timeType = dayjs(timestampValue).utc().tz(timeZone).format("A")
    const finalTimeZone = "LTZ"

    this.setState({
      GMTTimeStr,
      currentTimeStr,
      units,
      displayTtoD: true,
      year,
      month,
      day,
      hour,
      minutes,
      seconds,
      timeType,
      timeZone: finalTimeZone,
    })
  }

  getTimeMenu = () => {
    return ["AM", "PM"].map(item => (
      <Select.Option key={item} value={item} label={item}>
        {item}
      </Select.Option>
    ))
  }

  getTimezoneMenu = () => {
    return TIMEZONES.map(item => (
      <Select.Option key={item.key} value={item.value} label={item.value}>
        {item.label}
      </Select.Option>
    ))
  }

  updateOnChange({ key, value }) {
    let tempValue = value
    if (!["timeType", "timeZone"].includes(key)) {
      tempValue = value
        .split("")
        .filter(n => !Number.isNaN(Number(n)))
        .join("")
    }
    this.setState({ [key]: tempValue })
  }

  render() {
    const {
      year,
      month,
      day,
      hour,
      minutes,
      seconds,
      timeType,
      timeZone,
      unixTimestamp = "",
    } = this.state || {}

    return (
      <div id={'humanReadableToTimestampView'}>
        <Row style={{ marginTop: "50px" }}>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>Year</p>
            <span>
              <Input
                className={styles.inputLayouts}
                label="year"
                aria-label={"year"}
                style={{
                  fontSize: "14px",
                  width: "60px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  border: "1px solid #c7417b",
                }}
                value={year}
                onChange={e => {
                  const { value } = e.target
                  this.updateOnChange({ key: "year", value })
                }}
              />
            </span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>-</p>
            <span style={{ margin: "2px", fontWeight: "bold" }}>-</span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>Mon</p>
            <span>
              <Input
                className={styles.inputLayouts}
                label="month"
                aria-label={"month"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  border: "1px solid #c7417b",
                }}
                value={month}
                onChange={e => {
                  const { value } = e.target
                  this.updateOnChange({ key: "month", value })
                }}
              />
            </span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>-</p>
            <span style={{ margin: "2px", fontWeight: "bold" }}>-</span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>Day</p>
            <span>
              <Input
                className={styles.inputLayouts}
                label="day"
                aria-label={"day"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  border: "1px solid #c7417b",
                }}
                value={day}
                onChange={e => {
                  const { value } = e.target
                  this.updateOnChange({ key: "day", value })
                }}
              />
            </span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>&nbsp;</p>
            <span style={{ margin: "3px", fontWeight: "bold" }}>&nbsp;</span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>Hr</p>
            <span>
              <Input
                className={styles.inputLayouts}
                label="hour"
                aria-label={"hour"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  border: "1px solid #c7417b",
                }}
                value={hour}
                onChange={e => {
                  const { value } = e.target
                  this.updateOnChange({ key: "hour", value })
                }}
              />
            </span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>:</p>
            <span style={{ margin: "2px", fontWeight: "bold" }}>:</span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>Min</p>
            <span>
              <Input
                className={styles.inputLayouts}
                label="minutes"
                aria-label={"minutes"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  border: "1px solid #c7417b",
                }}
                value={minutes}
                onChange={e => {
                  const { value } = e.target
                  this.updateOnChange({ key: "minutes", value })
                }}
              />
            </span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>:</p>
            <span style={{ margin: "2px", fontWeight: "bold" }}>:</span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>Sec</p>
            <span>
              <Input
                className={styles.inputLayouts}
                label="seconds"
                aria-label={"seconds"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  border: "1px solid #c7417b",
                }}
                value={seconds}
                onChange={e => {
                  const { value } = e.target
                  this.updateOnChange({ key: "seconds", value })
                }}
              />
            </span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>&nbsp;</p>
            <span style={{ margin: "1px", fontWeight: "bold" }}>&nbsp;</span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>&nbsp;</p>
            <span>
              <Select
                className={styles.inputLayoutsSelect}
                label="am/pm"
                aria-label={"am/pm"}
                style={{
                  width: "75px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  marginBottom: "10px",
                  // border: '1px solid #c7417b',
                }}
                value={timeType}
                onChange={value => {
                  this.updateOnChange({ key: "timeType", value })
                }}
              >
                {this.getTimeMenu()}
              </Select>
            </span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>&nbsp;</p>
            <span style={{ margin: "1px", fontWeight: "bold" }}>&nbsp;</span>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <p style={{ margin: 0 }}>&nbsp;</p>
            <span>
              <Select
                className={styles.inputLayoutsSelect}
                label="timezone"
                aria-label={"timezone"}
                style={{
                  width: "120px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  marginBottom: "10px",
                  // border: '1px solid #c7417b',
                }}
                value={TIMEZONE_KEY_PAIRS[timeZone]}
                onChange={value => {
                  this.updateOnChange({ key: "timeZone", value })
                }}
              >
                {this.getTimezoneMenu()}
              </Select>
            </span>
          </Col>
          <Col>
            <span style={{ margin: "8px", fontWeight: "bold" }}>&nbsp;</span>
          </Col>
          <Col xs={24} sm={12} md={12} xl={9}>
            <p style={{ margin: 0 }}>&nbsp;</p>
            <Button
              className={styles.btnLayouts}
              type="primary"
              onClick={this.handleDateToTimestampConversionSubmit}
            >
              Human-Readable To SAS Timestamp
            </Button>
          </Col>
        </Row>
        {unixTimestamp && (
          <div style={{ marginTop: "10px" }}>
            <Row style={{ fontSize: "16px" }}>
              <Col md={4}>
                <p>
                  <b>SAS timestamp in seconds</b>
                </p>
              </Col>
              <Col>
                <p>:</p>
              </Col>
              <Col>
                <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                  &nbsp;{unixTimestamp && (Number(unixTimestamp + 315619200000) / 1000)}
                </p>
              </Col>
            </Row>
            <Row style={{ fontSize: "16px" }}>
              <Col md={4}>
                <p>
                  <b>SAS Timestamp in milliseconds </b>
                </p>
              </Col>
              <Col>
                <p>:</p>
              </Col>
              <Col>
                <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                  &nbsp;{unixTimestamp && Number(unixTimestamp + 315619200000)}
                </p>
              </Col>
            </Row>

            <Row style={{ fontSize: "16px" }}>
              <Col md={4}>
                <p>
                  <b>Date and time (Local Time) </b>
                </p>
              </Col>
              <Col>
                <p>:</p>
              </Col>
              <Col>
                <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                  &nbsp;{dayjs(unixTimestamp).format('LLLL')}
                </p>
              </Col>
            </Row>
          </div>
        )}
      </div>
    )
  }
}

export default SasHumanReadableToTimestampView
